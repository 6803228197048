import React from "react";
import config from "../../config/config";
import teamImg from "./../../assets/images/banners/why-us.svg";
// import icon from "./../../assets/images/icons/target.png";
const WhyUs = () => {
  return (
    <div>
      <h3 className="text-center">Why Us?</h3>
      <div className="row">
        <div className="col-md-6">
          <img src={teamImg} width="100%" alt="icon" />
        </div>
        <div className="col-md-6">
          <div className="row mt-5 text-justify mx-auto">
            <div className="col-11">
              <h4>{config.WhyUs[1].title}</h4>
              <p>{config.WhyUs[1].desc}</p>

              <h4>{config.WhyUs[2].title}</h4>
              <p>{config.WhyUs[2].desc}</p>

              <h4>{config.WhyUs[3].title}</h4>
              <p>{config.WhyUs[3].desc}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
