import React from "react";
import config from "../../config/config";

const Quote = () => {
  return (
    <div className="text-center">
      <h2>"{config.Quote}"</h2>
    </div>
  );
};

export default Quote;
