import React from "react";
import { NavLink, Link } from "react-router-dom";
import "./Navbar.css";
// import logo from "../../assets/images/logo/logo-horizontal.png";
// import logoWhite from "../../assets/images/logo/logo-horizontal-white.png";
import logoWhite from "../../assets/images/logo/logo-horizontal-white.svg";
const Navbar = () => {
  return (
    <div className="navigation fixed-top1">
      <nav className="navbar navbar-dark navbar-expand-lg">
        <Link to="/">
          <img
            className="navbar-brand"
            src={logoWhite}
            alt="logo"
            width="140px"
          />
        </Link>

        <button className="navbar-toggler">
          <span
            className="navbar-toggler-icon"
            data-toggle="collapse"
            data-target="#navMenu"></span>
        </button>

        <div className="collapse navbar-collapse" id="navMenu">
          <ul className="navbar-nav ml-auto font-weight-bold">
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="link-active">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/services"
                className="nav-link"
                activeClassName="link-active">
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                className="nav-link"
                activeClassName="link-active">
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact to="#" className="nav-link">
                Blogs
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink exact to="#" className="nav-link">
                +91-7758941912
              </NavLink>
            </li> */}
            <li className="nav-item my-auto">
              <Link
                exact
                to="/contact"
                className="btn btn-color font-weight-bold">
                Discuss Your Project
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
