import React from "react";
import "./Card.css";
import { Link } from "react-router-dom";

const Card = ({ heading, content, icon }) => {
  return (
    <div className="card p-3 m-3">
      <div className="icon p-4 m-3">
        <img src={icon} width="40%" />
      </div>

      <h5>{heading}</h5>

      <p>{content}</p>

      {/* <Link className="link" href="#" target="_blank" rel="noopener noreferrer">
        Read More -&gt;
      </Link> */}
    </div>
  );
};

export default Card;
