import React from "react";
import "./Cover.css";
import Banner from "../../assets/images/banners/team-handshaking.svg";
// import Banner from "../../assets/images/banners/banner-animated.png";
import "./Cover.css";
const Cover = ({ title, desc, width }) => {
  return (
    <div className="mb-5 p-3 main-cover">
      <div className="row">
        <div className="col-lg-7 col-12 p-lg-5">
          <div className="content-box p-lg-2">
            <h1>{title}</h1>
            <p>{desc}</p>
          </div>
        </div>

        <div className="col-lg-5 col-12 ">
          <img
            className="imgResize"
            src={Banner}
            alt="Team HandShake"
            width={width}
          />
        </div>
      </div>
    </div>
  );
};

export default Cover;
