import React from "react";
import { Link } from "react-router-dom";
// import Google_Partner from "../../assets/images/banners/google-partner.png";
import addvicLogo from "../../assets/images/partner/addvic-logo.svg";
// import Banner from "../../assets/images/banners/banner-animated.svg";
import Banner from "../../assets/images/banners/home-banner.png";
import "./HomeCover.css";
import config from "./../../config/config.js";
const HomeCover = () => {
  return (
    <div>
      <div className="row home-cover">
        <div className="col-lg-5 text-center">
          <img src={Banner} alt="Home Banner" width="80%" />
        </div>

        <div className="col-lg-7 p-5">
          <div className="content-box">
            <h1>{config.HomeBanner.mainHeading}</h1> <br />
            <p>{config.HomeBanner.desc}</p>
            <Link to="/services">
              <button className="btn btn-color">Learn More</button>
            </Link>
          </div>
          <br />

          <div className="google-box border border-primary p-3 row d-flex align-items-center">
            {/* <div className="col-sm-5">
              <img
                src={addvicLogo}
                alt="Training_Partner"
                width="100%"
                className=""
              />
            </div> */}
            {/* <div className="col-sm-7 pt-3 text-center"> */}
            <div className="col-12 pt-3 text-center">
              <h4>WE ALWAYS WORK WITH LATEST TECHNOLOGIES!</h4>
              <h6>
                At Addvic, we are dedicated to harnessing the power of
                cutting-edge technologies to deliver top-notch solutions to our
                clients.
              </h6>
            </div>
          </div>

          {/* <p>100+ Clients 140+ Projects 15+ Countries</p> */}
        </div>
      </div>
    </div>
  );
};

export default HomeCover;
