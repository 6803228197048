import React, { useState } from "react";
import "./ServicesTypes.css";
import ServiceTypeCard from "../ServiceTypeCard/ServiceTypeCard";
import config from "../../config/config";
const ServicesTypes = () => {
  const [Index, setIndex] = useState("Tab 1");
  return (
    <div className="p-5 main-container">
      <ul className="d-flex m-auto p-3 row">
        <h5
          className="col-md-3 w-100 mx-auto service-link"
          onClick={() => {
            setIndex("Tab 1");
          }}
        >
          {config.ServiceTypes.type1.tabTitle}
        </h5>
        <h5
          className="col-md-3 w-100 mx-auto service-link"
          onClick={() => {
            setIndex("Tab 2");
          }}
        >
          {config.ServiceTypes.type2.tabTitle}
        </h5>
        <h5
          className="col-md-3 w-100 mx-auto service-link"
          onClick={() => {
            setIndex("Tab 3");
          }}
        >
          {config.ServiceTypes.type3.tabTitle}
        </h5>
        <h5
          className="col-md-3 w-100 mx-auto service-link"
          onClick={() => {
            setIndex("Tab 4");
          }}
        >
          {config.ServiceTypes.type4.tabTitle}
        </h5>
      </ul>

      {
        (console.log(Index),
        Index === "Tab 1" ? (
          <ServiceTypeCard
            title={config.ServiceTypes.type1.heading}
            desc={config.ServiceTypes.type1.desc}
          />
        ) : Index === "Tab 2" ? (
          <ServiceTypeCard
            title={config.ServiceTypes.type2.heading}
            desc={config.ServiceTypes.type2.desc}
          />
        ) : Index === "Tab 3" ? (
          <ServiceTypeCard
            title={config.ServiceTypes.type3.heading}
            desc={config.ServiceTypes.type3.desc}
          />
        ) : Index === "Tab 4" ? (
          <ServiceTypeCard
            title={config.ServiceTypes.type4.heading}
            desc={config.ServiceTypes.type4.desc}
          />
        ) : null)
      }
    </div>
  );
};

export default ServicesTypes;
