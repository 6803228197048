import React from "react";
import config from "../../config/config";
import "./Testimonials.css";
const Testimonials = () => {
  return (
    <div className="text-center justify-content-center">
      <h1>Testimonials</h1>
      <div className="row m-1 justify-content-center">
        <div className="col-sm-12 col-md-5 col-lg py-5 m-1 card">
          <p>{config.Testimonials[1].bodyText}</p>
          <h6>{config.Testimonials[1].name}</h6>
        </div>

        <div className="col-sm-12 col-md-5 col-lg py-5 m-1 card">
          <p>{config.Testimonials[2].bodyText}</p>
          <h6>{config.Testimonials[2].name}</h6>
        </div>

        <div className="col-sm-12 col-md-5 col-lg py-5 m-1 card">
          <p>{config.Testimonials[3].bodyText}</p>
          <h6>{config.Testimonials[3].name}</h6>
        </div>

        <div className="col-sm-12 col-md-5 col-lg py-5 m-1 card">
          <p>{config.Testimonials[4].bodyText}</p>
          <h6>{config.Testimonials[4].name}</h6>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
