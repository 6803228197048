import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Cover from "../Components/Cover/Cover";
import Approach from "../Components/About/About";
import Contact from "../Components/Contact/Contact";
import Footer from "../Components/Footer/Footer";
import Quote from "../Components/Quote/Quote";
import WhyUs from "../Components/WhyUs/WhyUs";
import config from "../config/config";
import approchImg from "./../assets/images/banners/our-approach.svg";
const About = () => {
  return (
    <div className="overflow-hidden">
      <Navbar />
      <div className="overflow-hidden">
        <Cover
          title={config.AboutBanner.mainHeading}
          desc={config.AboutBanner.desc}
          width="500px"
        />
      </div>

      <div className="overflow-hidden mb-5 bg-light">
        <Approach
          link="/contact"
          section=""
          title={config.Approach.title}
          desc={config.Approach.desc}
          leftImg={approchImg}
        />
      </div>

      <div className="row text-center bg-primary text-white p-4 mb-5">
        <Quote />
      </div>

      <div className="overflow-hidden">
        <WhyUs />
      </div>

      <div className="overflow-hidden mb-0 mt-2">
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

export default About;
