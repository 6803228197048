const config = {
  // homepage configurations
  HomeBanner: {
    mainHeading: "YOU DREAM IT, WE BUILD IT...!",
    desc: "As India’s leading web development company, we believe that your website should be as unique as the customers visiting it... No matter Whether you are a local start-up or an established business, Addvic Technology has the expertise to develop the right solutions for your business!",
  },

  Services: {
    1: {
      title: "Website Design",
      desc: "Our experts work with you to brainstorm the best, stunning design and put everything in it to make it real.",
    },
    2: {
      title: "Web Development",
      desc: "We deliver intractive & SEO optimized websites well known for responsiveness & crystal clean design.",
    },
    3: {
      title: "WordPress Websites",
      desc: "We have dedicated wordpress experts ready to transform your idea into a real website. we also do provide support.",
    },
    4: {
      title: "Windows Applications",
      desc: "You can trust us for best in class windows forms/console applications development to fullfill all your business needs",
    },
  },

  About: {
    heading: "Why Choose US?",
    desc: "Addvic Technology is a leading software service and website development company. We are a creative team of website developers, UI designers, software developers and wordpress experts who are passionate to build smart solutions which are well optimized for SEO, mobile friendliness & other search ranking factors. We believe on the fact that client is the boss here & we're the servent... We work closely with you to design and build your digital products & We won't stop until you're fully satisfied with the work.",
  },

  Testimonials: {
    1: {
      bodyText:
        "It is a distinct pleasure for me to recommend Addvic Technology (OPC) Pvt Ltd to any and all interested parties. They have been professional, comprehensive and competent throughout the process of our working together. they did amazing job, Just Go for it..!",
      name: "Rahul Mahajan",
    },
    2: {
      bodyText:
        "We have had nothing but rave comments about the site from those who are closest to it and rely on it the most. I personally want to thank you and your staff for their patience and diligence in promptly meeting each of our requests for the site during its development.",
      name: "Success veda",
    },
    3: {
      bodyText:
        "What a helpful bunch. After being scammed by another web design company, I was recommended Addvic Technology and I now know why. They are quick to respond, informative and deliver what is asked for... Would highly recommend. Thanks guys!",
      name: "Sunny Patil",
    },
    4: {
      bodyText:
        "Addvic Technology have a passionate and very energetic team and are innovative in their approach in solving complex technical problems. I am impressed and satisfied with their ongoing WordPress development services and a big thank you for all the help",
      name: "Color Cat",
    },
  },

  Contact: {
    heading: "We're Here, Let's Talk!",
    desc: "Our team is always ready to hear you... Give us a call, drop us an email, or just fill in the form below to instantly schedule a call with an expert.",
  },

  Footer: {
    companyDetails:
      "Addvic Technology (OPC) Pvt Ltd is a windows/desktop and web development company that develops highly scalable, responsive, SEO optimized & lightning fast solutions. We work closely with you to design and build your digital products & presence. Our clients include several startups, personal brands and local businesses!",

    address: {
      line1: "+91-7758941912",
      line2: "info@addvic.com",
      line3:
        "Chanakya Nagari, Flat no. 307, 3rd Floor, Sinhgad Rd, above Dr. Dudhbhate Netralaya, Anand Nagar, Pune, Maharashtra 411051",
    },
  },

  // Services page configuration
  ServicesBanner: {
    mainHeading: "Top Notch Development Services",
    desc: "We work with the latest tech stacks to engineer front-end and back-end solutions, and do everything from single page progressive web applications in ReactJS, to multi-tier services, REST APIs in Python, node.js and deploy it on AWS. we're a company committed to develop highly scalable, responsive, SEO optimized & lightning fast solutions. We help you build what you always dreamed of... no matter it's a desktop application or a full fledged website! Addvic Technology is specialised in developing custom frontend and backend web solutions!",
  },

  ServiceTypes: {
    type1: {
      tabTitle: "E-Commerce Website",
      heading: "Advanced E-Commerce Website",
      desc: "An lighting fast, responsive & scalable website... that's what you need to get started with selling your products online.. but not everyone is good at managing all the technical things, that's where Addvic Technology comes into picture... we help you to create full fledged ecommerce system starting from product listing, on page SEO optimization, shipping to managing payment gateway integration, login, order processing, dashboard functionality and all.",
    },
    type2: {
      tabTitle: "Business Website",
      heading: "Corporate Website",
      desc: "A website acts as a first responder for a prospective client. For a customer, it is your website that creates the first ever impression in their minds. Therefore, it is important to make a lasting impression. Connect with Addvic Technology and transform your organization’s image with a premium quality corporate website, making your first impression, a lasting one. We deliver the website with customized design, easy to use backend, and complete on-page optimization (SEO).",
    },
    type3: {
      tabTitle: "News Website",
      heading: "News, Magzine Or Blogging Website",
      desc: "We are living in an digital era where even news is also getting digitized. Nowadays audience preferes to get news over their smartphones and desktops instead of traditional newspaper. We at Addvic Technology (OPC) Pvt Ltd offer you custom News Web Portal Design and Development Services including New Web App Development & Existing News Website Enhancement. We design and develop your news portal as per your custom requirements with dynamic categories and sections like regional and international news, lifestyle, sports etc.",
    },
    type4: {
      tabTitle: "Listing Website",
      heading: "Listing Or Catalogue Website",
      desc: "Unable to figure out what you really need? Don’t let the limitations of cookie-cutter solutions control your website’s functionality and business workflow. Our team of expert designers, web strategists, and web developers will work with you to develop a custom solution that will perfectly fit your needs. If you can dream it, we can build it. With us, there are absolutely no restrictions and we do 100% of our work in-house. Our team is versed in a wide range of contemporary technologies including WordPress, ReactJS, C#.NET as well as core PHP & Java.",
    },
  },

  CaseStudies: {
    1: {
      title: "Color Cat",
      desc: "Color Cat is a emerging startup company excelling in artistry industry providing a free platform to numerous small scale artists all over the globe. They kept the platform opened for different types of art submissions like paintings, graphics, interior designs and many others helping the artists to present their art in front of large number of audience... Addvic Technology designed a unique and creative UI for this website that made it stand out from its competitors.",
    },
  },

  // About Page Configuration
  AboutBanner: {
    mainHeading: "Team of Skilled Designers & Developers",
    desc: "We deliver unique websites to clients across a wide range of sectors. We have a team of experts in website design, website development, e-commerce websites, & CMS website development. We are a top-notch web development company in India that can help you define your brand and increase it's demand through a customer-centric and data-driven approach. We understand the importance of having an engaging website in today’s digital era keeping in mind imp aspects like SEO, PPC and more to yield outstanding results.",
  },

  Approach: {
    title: "Our Approach",
    desc: "We are proud of having an energetic group of youthful and creative developers and specialists that gives us the benefit to have unique perspective at the things in an unexpected way. Our client driven way of planning urges our customers to share their business needs. This helps us to match the viewpoint of both the customer and the company to shape the finished product in a much better way. we believe on increasing your product quality through a customer-centric and data-driven development approach.",
  },

  Quote: "We don't just sell websites, we create websites that brings value..!",

  WhyUs: {
    1: {
      title: "Innovative Process",
      desc: "We have faith in keeping imagination in charge of things at our working environment. Our creative methodology of making no two systems comparable is the thing that makes us interesting.",
    },
    2: {
      title: "Research Oriented",
      desc: "We do complete exploration that assists us with choosing the right technology to convey flawless development and guarantee you best in class service.",
    },
    3: {
      title: "Excellence in Delivery",
      desc: "We have a demonstrated history of giving world class web development services to many number of cheerful clients. We never stop until we give you what you exactly dreaming of..!",
    },
  },
};

export default config;
