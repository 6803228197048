import React from "react";
import Card from "./../Card/Card";
import webDevIcon from "../../assets/images/icons/web-dev.png";
import config from "../../config/config";
const Services = () => {
  return (
    <div>
      <div className="text-center">
        <h1>Check Out Our Specialized Services</h1>

        <div className="mt-3 row">
          <div className="col-sm-12 col-md-6 col-lg d-flex">
            <Card
              heading={config.Services[1].title}
              content={config.Services[1].desc}
              icon={webDevIcon}
            />
          </div>

          <div className="col-sm-12 col-md-6 col-lg d-flex">
            <Card
              heading={config.Services[2].title}
              content={config.Services[2].desc}
              icon={webDevIcon}
            />
          </div>

          <div className="col-sm-12 col-md-6 col-lg d-flex">
            <Card
              heading={config.Services[3].title}
              content={config.Services[3].desc}
              icon={webDevIcon}
            />
          </div>

          <div className="col-sm-12 col-md-6 col-lg d-flex">
            <Card
              heading={config.Services[4].title}
              content={config.Services[4].desc}
              icon={webDevIcon}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
