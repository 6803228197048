import React, { useState } from "react";
import "./Contact.css";
import contactImg from "../../assets/images/banners/get-in-touch.png";
import config from "../../config/config";
import emailJS from "emailjs-com";
const Contact = () => {
  const [Data, setData] = useState({
    Name: null,
    Email: null,
    Mobile: null,
    Msg: null,
  });

  const submitForm = (e) => {
    e.preventDefault();
    // console.log("submit clicked!")/;
    console.log(Data);

    emailJS
      .sendForm(
        "zicFlow_Website",
        "default_zicflow_template",
        e.target,
        "user_U669XZMPbiUtprmjV7rpy"
      )
      .then((res) => {
        var lblStatus = document.getElementById("lblStatus");
        if (res.status === 200) {
          lblStatus.innerText = "Thank You, We'll Contact You Soon!";
          lblStatus.style.color = "yellow";
        } else {
          lblStatus.innerText = "Unknown Error, Whatsapp Us at +91-7758941912";
          lblStatus.style.color = "red";
        }
      });
  };

  return (
    <div>
      <div className="row contact-card">
        <div className="banner col-sm-12 col-lg-4 col-12 my-auto">
          <img src={contactImg} alt="get in touch" width="100%" />
        </div>

        <div className="col-sm-12 col-lg p-5 m-3">
          <h1>{config.Contact.heading}</h1>
          <p>{config.Contact.desc}</p>

          <form onSubmit={submitForm}>
            <div className="row">
              <input
                type="text"
                name="Name"
                placeholder="Name"
                className="col-md-3 mx-auto m-sm-3 my-2 p-2"
                onChange={(e) => {
                  setData({ ...Data, Name: e.target.value });
                }}
              />
              <input
                type="text"
                name="Email"
                placeholder="Email"
                className="col-md-3 mx-auto m-sm-3 my-2 p-2"
                onChange={(e) => {
                  setData({ ...Data, Email: e.target.value });
                }}
              />
              <input
                type="text"
                name="Mobile"
                placeholder="Mobile"
                className="col-md-3 mx-auto m-sm-3 my-2 p-2"
                onChange={(e) => {
                  setData({ ...Data, Mobile: e.target.value });
                }}
              />
            </div>
            <div className="row">
              <textarea
                cols="40"
                width="100%"
                rows="5"
                name="Message"
                placeholder="Message"
                className="col-md-10 mx-auto m-sm-3 my-2 p-2"
                onChange={(e) => {
                  setData({ ...Data, Msg: e.target.value });
                }}></textarea>
            </div>

            <div className="text-center">
              <p id="lblStatus"></p>
            </div>

            <button className="btn btn-color" type="submit">
              SUBMIT
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
