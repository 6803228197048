import React from "react";
import { Link } from "react-router-dom";
import "./About.css";
import aboutImg from "../../assets/images/banners/about.png";
const About = ({ link, section, title, desc, leftImg }) => {
  return (
    <div>
      <div className="row mx-auto">
        <div className="col-sm-11 col-md-6 col-lg mx-auto my-auto">
          <img src={leftImg} width="90%" />
        </div>
        <div className="col-sm-11 col-md-5 col-lg my-4 mx-2 px-1 mx-auto p-4 text-justify">
          <h5 className="m-2 mb-3">{section}</h5>
          <h1>{title}</h1>
          <p>{desc}</p>
          <Link to={link} className="btn btn-color">
            Discover More -&gt;
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
