import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Cover from "../Components/Cover/Cover";
import About from "../Components/About/About";
import Contact from "../Components/Contact/Contact";
import Footer from "../Components/Footer/Footer";
import ServicesTypes from "../Components/ServicesTypes/ServicesTypes";
import ServicesBlocks from "../Components/Services/Services";
import config from "../config/config";
import colorCatImg from "./../assets/images/clients/colorcat.png";
const Services = () => {
  return (
    <div className="overflow-hidden">
      <div className="">
        <Navbar />
      </div>

      <div className="">
        <Cover
          title={config.ServicesBanner.mainHeading}
          desc={config.ServicesBanner.desc}
          width="500px"
        />
      </div>

      <div className="mb-5 bg-light">
        <ServicesTypes />
      </div>

      <ServicesBlocks />

      <div className="mb-3 mt-5 pt-3 bg-light">
        <h1 className="text-center">Website Development Case Studies</h1>
        <About
          link="/contact"
          // section="Web Development Project"
          title={config.CaseStudies[1].title}
          desc={config.CaseStudies[1].desc}
          leftImg={colorCatImg}
        />
      </div>

      <div className="mb-0">
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

export default Services;
