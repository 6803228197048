import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
// import logo from "../../assets/images/logo/logo-horizontal-white.png";
import logo from "../../assets/images/logo/logo-horizontal-white.svg";
import config from "../../config/config";
const Footer = () => {
  return (
    <div>
      <div className="row footer">
        <div className="col-md-6 col-12 px-5 text-justify logo-box">
          <img src={logo} alt="logo" className="footer-logo mt-3" width="40%" />
          <br />
          <p>{config.Footer.companyDetails}</p>
        </div>
        <div className="col-md-3 col-5 block">
          <h5 className="text-white">Quick Links</h5>

          <Link to="/about">About</Link>
          <br />
          <Link to="/services">Services</Link>
          <br />
          <Link to="/#">FAQ’s</Link>
          <br />
          <Link to="/#">Terms and Conditions</Link>
          <br />
          <Link to="/#">Privacy Policy</Link>
          <br />
          <Link to="/contact">Contact Us</Link>
          <br />
        </div>
        <div className="col-md-3 col-5 block">
          <h5 className="text-white">Contact US</h5>

          <a href="tel:+91-7758941912">
            <p>{config.Footer.address.line1}</p>
          </a>

          <p>{config.Footer.address.line2}</p>
          <p>{config.Footer.address.line3}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 bg-success text-white text-center">
          <p>
            Copyright &copy; 2021 - Addvic Technology (OPC) Pvt Ltd | All Rights
            Reserved!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
