import React from "react";
import "./Client.css";
import clientstrip from "../../assets/images/clients/client-strip.png";
const Client = () => {
  return (
    <div className="m-4">
      <h5>Clients We Work With:</h5>

      <div className="border">
        <img src={clientstrip} alt="colorcat.in" width="100%" />
      </div>
    </div>
  );
};

export default Client;
