import React from "react";
import Navbar from "./../Components/Navbar/Navbar";
import HomeCover from "../Components/HomeCover/HomeCover";
import Client from "./../Components/Client/Client";
import Services from "./../Components/Services/Services";
import About from "./../Components/About/About";
import Testimonials from "./../Components/Testimonials/Testimonials";
import Contact from "./../Components/Contact/Contact";
import Footer from "./../Components/Footer/Footer";
import config from "../config/config";
import whyUsImg from "./../assets/images/banners/why-us.svg";

const Home = () => {
  return (
    <div>
      <div>
        <div className="navigation">
          <Navbar />
        </div>
        <div className="overflow-hidden mb-5">
          <HomeCover />
        </div>
        <div className="overflow-hidden mb-4">
          <Client />
        </div>
        <div className="overflow-hidden mb-4" id="services">
          <Services />
        </div>
        <div className="overflow-hidden p-3 m-2 mb-4">
          <About
            link="/about"
            // section="ABOUT US"
            title={config.About.heading}
            desc={config.About.desc}
            leftImg={whyUsImg}
          />
        </div>
        <div className="overflow-hidden mb-4">
          <Testimonials />
        </div>
        <div className="overflow-hidden mb-0">
          <Contact />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
