import React from "react";
import "./ServiceTypeCard.css";
const ServiceTypeCard = ({ title, desc }) => {
  return (
    <div className="row mx-auto p-5 main-card">
      <h4>{title}</h4> <br />
      <p>{desc}</p>
    </div>
  );
};

export default ServiceTypeCard;
